import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ChatForm from "../components/forms/chat-form"

const Chat = () => {
  function showChat(e) {
    e.preventDefault()
    document.getElementById("message-container").classList.add("open")
  }
  function hideChat(e) {
    e.preventDefault()
    document.getElementById("message-container").classList.remove("open")
  }
  const data = useStaticQuery(graphql`
    query ChatDataQuery {
      site {
        siteMetadata {
          phone
        }
      }
    }
  `)
  return (
    <div id="message-container">
      <div className="message-me" id="message-call">
        <a
          className="message-me__form-head"
          id="message-show"
          onClick={showChat}
        >
          <div className="message-me__icon-holder">
            <i className="icon icon_chat" />
          </div>
        </a>
      </div>
      <div className="message-me" id="message-main">
        <div className="message-me__form-head">
          <div className="message-me__icon-holder">
            <i className="icon icon_steve" />
          </div>
          <span className="message-me__title">
            Call us anytime at {data.site.siteMetadata.phone}, or leave a
            message and we'll reach out when it works for you.
          </span>
          <a
            id="message-hide"
            className="message-me__btn-close"
            onClick={hideChat}
          />
        </div>
        <ChatForm />
      </div>
    </div>
  )
}

export default Chat
