import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import Logo from "../assets/images/mma/mma-logo-1.png"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
          phone
        }
      }
    }
  `)
  function toggleMobileMenu(e) {
    e.preventDefault()
    document.body.classList.toggle("menu-open")
  }
  function toggleSubmenu(e) {
    e.preventDefault()
    e.target.nextSibling.classList.toggle("active")
  }
  const linkPhone =
    "tel:+1" + data.site.siteMetadata.phone.replace(/[^\d]/g, "")
  return (
    <header className="header" id="sticky-header">
      <div className="container">
        <div className="header-container">
          <strong className="logo">
            <a href="/" title={data.site.siteMetadata.siteName}>
              <img
                className="img-responsive"
                src={Logo}
                alt={data.site.siteMetadata.siteName}
              />
            </a>
          </strong>
          <a href={data.site.siteMetadata.siteName} className="call-phone">
            <i className="my-icon icon-mobile" />
            {data.site.siteMetadata.phone}
          </a>
          <a
            id="toggle-menu"
            className="hamburger hamburger--collapse is-active"
            href="#nav"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
            <em className="hamburger-box__text">Menu</em>
          </a>
          <nav className="main-nav" id="nav">
            <ul>
              <li className="scroll-to">
                <a onClick={() => scrollTo("#do")}>What We Do</a>
              </li>
              <li className="scroll-to">
                <a onClick={() => scrollTo("#case-studies")}>
                  Case Studies &amp; References
                </a>
              </li>
              <li className="scroll-to">
                <a onClick={() => scrollTo("#about")}>About Us</a>
              </li>
              <li className="main-nav__item_audit scroll-to">
                <a onClick={() => scrollTo("#contact-form")}>Contact us</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
