import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useForm } from "react-hook-form"

const ChatForm = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formsAction {
            chatForm
          }
        }
      }
    }
  `)
  const [submitted, setSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm() // initialise the hook
  const FORM_GATEWAY_URL = data.site.siteMetadata.formsAction.chatForm
  const onSubmit = async data => {
    try {
      const formData = new URLSearchParams()
      for (const name in data) {
        formData.append(name, data[name])
      }
      await fetch(FORM_GATEWAY_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data)
        })
      setSubmitted(true)
      reset()
    } catch (error) {
      setError(
        "submit",
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      )
    }
  }
  const showSubmitError = msg => <p className="msg-error">{msg}</p>
  const dateTimeChange = e => {
    const consultDate = e.format("MM/DD/YYYY HH:mm")
    setValue("yourTimeContact", consultDate)
  }
  // phonemask
  const phoneMaskChange = e => {
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
  }
  const showThankYou = (
    <div className="msg-confirm">
      <p>Awesome! Your message was sent.</p>
      <button
        type="button"
        className="btn_message"
        onClick={() => setSubmitted(false)}
      >
        Send another message?
      </button>
    </div>
  )
  const showForm = (
    <form
      onSubmit={handleSubmit(onSubmit)}
      method="post"
      className="wpcf7-form wpcf7"
    >
      <div className="message-me__field">
        <input
          className="wpcf7-form-control"
          type="text"
          placeholder="Name"
          name="yourName"
          ref={register({ required: true })}
        />
        {errors.yourName && (
          <span className="wpcf7-not-valid-tip">This field is required</span>
        )}
      </div>
      <div className="message-me__field">
        <input
          className="wpcf7-form-control"
          type="text"
          placeholder="Phone"
          name="yourPhone"
          onChange={phoneMaskChange}
          ref={register({ required: true })}
        />
        {errors.yourPhone && (
          <span className="wpcf7-not-valid-tip">This field is required</span>
        )}
      </div>
      <div className="message-me__field">
        <input
          className="wpcf7-form-control"
          type="text"
          placeholder="Email"
          name="yourEmail"
          ref={register({ required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors.yourEmail && (
          <span className="wpcf7-not-valid-tip">This field is required</span>
        )}
      </div>
      <div className="message-me__field">
        <input
          className="wpcf7-form-control"
          type="text"
          placeholder="Best Day/Time to Call"
          name="yourTimeContact"
        />
      </div>
      <div className="message-me__field">
        <textarea
          className="wpcf7-form-control"
          name="your-message"
          cols={30}
          rows={10}
          placeholder="Questions/Comments"
          name="yourMessage"
          ref={register}
        />
      </div>
      <div className="message-me__action">
        <button className="btn_message" type="submit" disabled={isSubmitting}>
          Send Message &gt;
        </button>
      </div>
      <span className="txt-conf">100% Confidential</span>
    </form>
  )
  return (
    <div className="message-me__form">
      {errors && errors.submit && showSubmitError(errors.submit.message)}
      {submitted ? showThankYou : showForm}
    </div>
  )
}

export default ChatForm
