import React from "react"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import Logo from "../assets/images/mma/mma-logo-1.png"
import ImgGooglePartner from "../assets/images/google-partner.jpg"

const Footer = () => (
  <footer className="new-footer">
    <div className="container">
      <div className="new-footer__flex">
        <div className="new-footer__column new-footer__column-1">
          <section className="widget">
            <h4 className="widget-title">Services</h4>
            <ul className="menu">
              <li>Google Adwords Management</li>
              <li>Bing Ads Management</li>
              <li>Search Engine Optimization</li>
              <li>Websites and Landing Pages</li>
              <li>Retargeting</li>
              <li>Display Advertising</li>
              <li>Geofencing</li>
              <li>Pay-Per-Call</li>
              <li>Conversion Rate Optimization</li>
            </ul>
          </section>
        </div>
        <div className="new-footer__column new-footer__column-2">
          <section className="widget">
            <h4 className="widget-title">About</h4>
            <ul className="menu">
              <li>
                <a onClick={() => scrollTo("#about")}>Our CEO</a>
              </li>
              <li>
                <a onClick={() => scrollTo("#case-studies")}>Case Studies</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
            </ul>
          </section>
        </div>
        <div className="new-footer__column new-footer__column-3">
          <section className="widget">
            <h4 className="widget-title">Corporate Headquarters</h4>
            <address>
              Medical Marketing Arm, Inc. <br /> 270 N. El Camino Real. F-435
              <br /> Encinitas, CA 92024
            </address>
            <a href="/" className="footer-logo">
              <img
                className="img-responsive"
                src={Logo}
                alt="MedicalMarketingArm"
              />
            </a>
          </section>
        </div>
        <div className="new-footer__column new-footer__column-4">
          <section className="widget">
            <h4 className="widget-title">Proudly Serving</h4>
            <p>
              We proudly serve small to mid-sized business owners throughout the
              United States and abroad. We are available 7 days a week for phone
              and Skype consultations, and we can meet you in person at your
              home or business as well. Feel free to reach out if you think we
              may be a fit.
            </p>
            <div className="partner-logo">
              <img src={ImgGooglePartner} alt="google-partner" />
            </div>
          </section>
        </div>
      </div>
      <div className="new-footer__summary">
        <p>
          © 2011 - 2020 Medical Marketing Arm. All rights Reserved.{" "}
          <Link to="/privacy-policy/">Privacy Policy</Link>
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
